<template>
    <div>
        <div class="row justify-content-center">
          <div class="col-11">
              <div class="rounded-lg shadow p-3 bg-white mt-5">
                <p class="font-semibold text-sm text-justify">Menu ini berguna untuk melakukan scan item untuk mendapatkan barcode dari item!</p>
                <hr>
                <div class="text-center">
                    <button type="button" class="btn btn-sm btn-dark  " @click="scanning"><span class="typcn typcn-code"></span> scan</button>
                </div>
              </div>
              <div class="rounded-lg shadow p-3 bg-white mt-5 animate__animated animate__backInUp" v-if="scan">
                <p class="font-semibold text-sm text-justify">Data Item !</p>
                <hr>
                <svg id="barcode"></svg>
                <table class="table tbale-sm table-striped table-bordered">
                    <tr>
                        <td>{{id}}</td>
                        <td>{{format}}</td>
                    </tr>
                </table>
                <div class="text-center">
                    <button type="button" class="btn btn-sm btn-dark  "><span class="typcn typcn-code"></span> scan</button>
                </div>
              </div>
          </div>
        </div>
    </div>
</template>
<script>
import JsBarcode from 'jsbarcode'
import firebase from 'firebase'
import autophp from '@/plugins/autophp_kasir';
import uniqid from 'uniqid';
let sdb = new autophp();
let rdb=firebase.database();
export default{
    layout:'app_shop_admin',
    data(){
        return{
            scan:false,
            id:'',
            format:''
        }
    },
    watch:{
        id(){
            this.scan=true;
            JsBarcode("#barcode", this.id);
            this.$forceUpdate();
        }
    },
    mounted() {
        let that=this
        rdb.ref(`/scan/${that.$store.state.users.id}/barcode`).on('value',(e)=>{ //read
            that.id=e.val().id
            that.format=e.val().format
        }) 
    },
    methods: {
        scanning(){
            let that=this
             if (typeof cordova == "object") {
                document.addEventListener("deviceready", function() {
                    cordova.plugins.barcodeScanner.scan(
                    function (result) {
                        rdb.ref(`/scan/${that.$store.state.users.id}/barcode`).set({
                            id:result.text,
                            format:result.format,
                            uniqid:uniqid()
                        })
                        sdb.alert('Berhasil Scan','bg-green-400' )
                    },
                    function (error) {
                        sdb.alert("Scanning failed: " + error, 'bg-red-400')
                    },
                    {
                        preferFrontCamera : false, // iOS and Android
                        showFlipCameraButton : true, // iOS and Android
                        showTorchButton : true, // iOS and Android
                        torchOn: false, // Android, launch with the torch switched on (if available)
                        saveHistory: true, // Android, save scan history (default false)
                        prompt : "Place a barcode inside the scan area", // Android
                        resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                        formats : "QR_CODE,PDF_417,DATA_MATRIX,UPC_A,UPC_E,EAN_8,EAN_13,CODE_39,CODE_93,CODE_128,CODABAR,ITF,RSS14,RSS_EXPANDED,AZTEC", // default: all but PDF_417 and RSS_EXPANDED
                        orientation : "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
                        disableAnimations : true, // iOS
                        disableSuccessBeep: false // iOS and Android
                    }
                );
                });
            }
        }
    },
}
</script>